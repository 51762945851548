.pdf-button {
	width: 70%;
	margin: auto;
	max-width: 500px;
	min-width: 300px;
	height: 65px;
	background-color: rgba(255,255,255,0.1);
	padding: 10px;
	border-radius: 10px;
	overflow: hidden;
	display: flex;
	color: white;
	text-shadow: 1px 1px 2px black;
	text-align: center;
	align-items: center;
}
.pdf-button > img {
	height: 100%;
	width: auto;
	margin-right: 10px;
}