.partner-card {
	width: 300px;
	height: 100px;
	text-align: -webkit-center;
	text-align: center;
	margin-top: 50px;
}

.partner-card > img {
    height: 100%;
    margin: auto auto 20px;
    width: 100%;
    object-position: center;
    object-fit: contain;
    overflow: visible;
}

.partner-card > img.varga_konyveloiroda_logo_svg_dark {
	filter: invert(1) brightness(2);
}

.partner-card > span {
	padding: 10px 20px;
	color: #ffffff;
	font-family: Poppins;
	font-size: 18px;
}

.partner-card > img.circle_logo {
	width: 100px;
    background-color: white;
    border-radius: 50%;
}

.partner-card > .partner-name {
	color: white;
    text-align: center;
}
