.products > .item {
	transition: 0.3s all;
	margin: 15px auto;
	height: 150px;
	width: 90%;
	min-width: 350px;
	max-width: 500px;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	padding: 10px;
	display: flex;
	align-items: center;
	vertical-align: middle;
	box-shadow: 3px 3px 0.5em rgba(0,0,0,0.5);
	overflow: hidden;
}

.products > .item:hover {
	background-color: rgba(255,255,255,0.2);
}

.products > .item > img {
	height: 100%;
	width: auto;
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	margin-right: 20px;
	box-shadow: 2px 2px 0.5em rgba(0, 0, 0, 0.5);
}

.products > .item > .content {
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
}

.products > .item > .content > .book-details {
	display: flex;
	flex-flow: column;
	font-size: 24px;
	text-shadow: 1px 1px 2px black;
}

.products > .item > .content > .book-details > .book-title {
	font-size: larger;
	color: white;
}

.products > .item > .content > .book-details > .book-author {
	font-size: small;
	color: rgb(200, 200, 200);
	font-style: italic;
}

.products > .item > .content > .buttons {
	display: flex;
    align-self: flex-end;
	height: 50px;
}

.social-button.facebook {
	display: flex;
	align-items: center;
	border-radius: 5px;
	background-color: rgb(66,103,178);
	color: rgb(255,255,255);
	margin-left: 5px;
	margin-top: 2px;
    padding: 0 5px 0 0;
    justify-content: center;
}

.social-button.facebook > svg {
	width: 39px;
	height: 39px;
	font-size: 2rem;
}

.products > .item > .content > .buttons > .facebook-button {
	margin: auto;
}

.products > .item > .content > .buttons > .google-button {
	height: 100%;
    text-align: center;
    text-align: -webkit-center;
}

.products > .item > .content > .buttons > .google-button > img {
	height: 100%;
}
