.navbar-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: 1200px;
  align-self: auto;
  align-items: center;
  flex-shrink: 1;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-fiveunits);
}
.navbar-image {
  width: 200px;
  height: 96px;
  object-fit: cover;
}
.navbar-dakaro-logo-reflection{
  position: absolute;
  transform: scaleY(-1) ;
  width: 200px;
  height: 96px;
  bottom: -5px;
  mask: linear-gradient(to top, rgba(0, 0, 0, 0.15),  transparent);
}
.navbar-nav-content {
  gap: var(--dl-space-space-threeunits);
  width: 787px;
  display: flex;
  align-self: center;
  align-items: center;
  margin-left: auto;
  flex-direction: row;
  justify-content: flex-end;
}
.navbar-nav-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  border-color: transparent;
}
.navbar-link {
  text-decoration: none;
}
.navbar-text2 {
  color: rgb(255, 255, 255);
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  font-stretch: normal;
  text-decoration: none;
}
.navbar-hamburger {
  display: none;
}
.navbar-image1 {
  width: 100px;
  object-fit: cover;
}
.navbar-mobile-menu {
  gap: var(--dl-space-space-twounits);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.3s;
  align-items: stretch;
  flex-direction: column;
  background-color: rgba(255,255,255,0.01);
  backdrop-filter: blur(5em);
}
.navbar-branding {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-image2 {
  width: 60%;
  height: auto;
  filter: drop-shadow(0 10px 1em rgba(15,15,15,0.5));
  object-fit: cover;
}
.navbar-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-icon {
  width: 24px;
  height: 24px;
}
.navbar-nav-links1 {
  gap: var(--dl-space-space-unit);
  color: var(--dl-color-gray-black);
  display: flex;
  flex-direction: column;
}
.navbar-text3 {
  color: rgba(255, 255, 255, 1);
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  font-stretch: normal;
  text-decoration: none;
}

@media(max-width: 991px) {
  .navbar-nav-content {
    width: 704px;
  }
  .navbar-link {
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .navbar-navbar {
    padding-bottom: var(--dl-space-space-fourunits);
    margin-bottom: 90px;
    justify-content: center;
  }
  .navbar-nav-links {
    display: none;
  }
  .navbar-get-started {
    display: none;
  }
  .navbar-hamburger {
    background-color: #0066FF;
    box-shadow: 0 0 1em #000000;
    display: flex;
    position: fixed;
    top:30px;
    right: 30px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  #close-mobile-menu {
    position: absolute;
    top: 30px;
    right: 30px;
  }
  #close-mobile-menu > svg {
    fill: #ffffff;
    width: 50px;
    height: 50px;
    filter: drop-shadow(2px 2px 2px rgba(0,0,0,1));
  }
  .navbar-image1 {
    width: 16px;
    height: 14px;
  }
  .navbar-branding {
    text-align: center;
    width: 100%;
  }
  .navbar-nav-links1 {
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .nav-link {
    font-size: 28px;
  }
  .navbar-image{
    position: absolute;
    text-align: center;
    top: 5px;
  }
  .navbar-dakaro-logo-reflection{
    top: 90px;
  }
}
