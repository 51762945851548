.products {
	/* background-color: rgba(255, 255, 255, 0.03); */
	/* overflow: auto; */
	max-width: 600px;
	width: 100%;
	min-width: 420px;
	/* height: 500px; */
	align-items: center;
	/* margin: 30px auto; */
	/* border-radius: 10px; */
	padding: 15px 0;
	/* box-shadow: inset 0 0 2em rgb(0 0 0 / 30%), inset 0 0 1em rgb(0 0 0 / 30%), inset 0 0 0.5em rgb(0 0 0 / 50%); */
}

.products::-webkit-scrollbar {
	width: 12px;
}

.products::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
	border-radius: 10px;
}

.products::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
